<template>
  <div class="Site PageBox">
    <van-nav-bar
            fixed
            :border="false"
            :title="$t('task.tabs[3]')"
    />
    <div class="ScrollBox">
      <van-tabs
              :border="false" color="#DAA78A"
              background="#0F4F4E"
              title-active-color="#DAA78A"
              title-inactive-color="#FFFEFF"
              z-index="99"
              :fixed="false"
              icon-size="60"
              v-model="tabsIndex"
              @change="changeTabs"
      >
        <van-tab
                v-for="(item, index) in taskTabs":key="item.state"
                :title="item.text"
        />
      </van-tabs>
      <van-pull-refresh v-model="isRefresh" @refresh="onRefresh">
        <van-list
                v-model="isLoad"
                :finished="isFinished"
                @load="onLoad"
        >
          <van-cell
                  class="FundItem" :border="false"
                  v-for="(item, index) in listData[tabsIndex]"
                  :key="item.id" @click="gotoTask(item)">
            <template>
              <div style="color:#8CBFC0">
                <table>
                  <tr>
                    <td width="65%" style="color: #e7e7e7">
                      {{ item.username}}
                    </td>
                    <td :class="item.status>2?'orderStatus2':'orderStatus'">
                      <span>{{ item.statestr}}</span>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      {{$t('hall.list[1]')}}: {{ parseFloat(item.price).toFixed(2) }} {{InitData.usdtinfo.sign}}
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      {{$t('hall.list[0]')}}:  {{ item.amount }} USDT

                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      {{ item.adddate }}
                    <span style="font-size: 18px;color: #17D0D2;padding-left: 80px">
                      {{ parseFloat(item.price*item.match_number).toFixed(2) }} {{InitData.usdtinfo.sign}}
                    </span>
                    </td>
                  </tr>
                </table>
              </div>
            </template>
          </van-cell>
        </van-list>
      </van-pull-refresh>
      <div style="height: 80px;"/>
    </div>
    <Footer />
  </div>
</template>

<script>
  import $ from "jquery";
  export default {
    name: "TaskRecord",
    props: [],
    data() {
      return {
        listData: [],
        isLoad: false,
        isFinished: false,
        isRefresh: false,
        pageNo: 0,
        tabsState: 0,
        tabsIndex: 0,
        barActive:3,
        taskTabs2: [12,3,3,44],
        taskTabs: [
          {
            state: 0,
            text: this.$t("task3[0]"),
            img: "./static/icon/taskbar_1.png",
          },
          {
            state: 1,
            text: this.$t("task3[1]"),
            img: "./static/icon/taskbar_2.png",
          },
          {
            state: 2,
            text: this.$t("task3[2]"),
            img: "./static/icon/taskbar_3.png",
          },
          // {
          //   state: 3,
          //   text: this.$t("task.tabs[3]"),
          //   img: "./static/icon/taskbar_4.png",
          // }
        ],
        tabbar_selete: [
          "./static/icon/taskbar_01.png",
          "./static/icon/taskbar_02.png",
          "./static/icon/taskbar_03.png",
          "./static/icon/taskbar_04.png",
        ],
        fileList: [],
        beforeIndex: 0,
        taskItem: {
          id: "",
          index: "", //下标
        }, //点击保存每一项任务
        show: false,
        tc_type: "",
      };
    },
    components: {
    },
    created() {
      var ll = this.taskTabs.length;
      this.listData = [];
      for (var i = 0; i < ll; i++) {
        this.listData[i] = [];
      }
    },
    mounted() {

    },
    methods: {
      onLoad() {
        this.getListData("load");
      },
      changeTab(index){
        this.barActive = index
        this.getListData("init");
      },
      changeTabs(index) {
        this.tabsState = this.taskTabs[index].state;
        console.log(this.tabsState);
        this.getListData("init");
      },
      getListData(type) {
        this.isLoad = true;
        this.isRefresh = false;
        if (type == 'load') {
          this.pageNo += 1;
        } else {
          this.pageNo = 1;
          this.isFinished = false;
        }
        let param = {
          // status: this.barActive,
          page_no: this.pageNo,
          is_u: 2
        }
        if (this.tabsState>0){
          param['pay_state'] = this.tabsState
        }
        var that = this;
        this.$Model.GetTaskRecord(
                param,
                data => {
                  this.isLoad = false;
                  if (data.code == 1) {
                    var items00 = data.info;
                    var items = [];
                    for (var i=0;i<items00.length;i++){
                      var item = items00[i];
                      var task_type = item['task_type']
                      var ordertype = item['ordertype']
                      var typestr = this.$t('hall4[6]')
                      if (task_type==2||ordertype==2)
                        typestr = this.$t('hall4[7]')
                      item['task_typestr'] = typestr;
                      var pay_state = item['pay_state']
                      var statestr = this.$t('task3[1]')
                      if (pay_state==2){
                        statestr = this.$t('task3[2]')
                      }
                      item['statestr'] = statestr;
                      items.push(item)
                    }
                    if (type == 'load') {
                      this.listData[this.tabsIndex] = this.listData[this.tabsIndex].concat(items);
                    } else {
                      this.listData[this.tabsIndex] = items;
                    }
                    if (this.pageNo == data.data_total_page) {
                      this.isFinished = true;
                    } else {
                      this.isFinished = false;
                    }
                  } else {
                    this.listData[this.tabsIndex] = '';
                    this.isFinished = true;
                  }
                }
        );
      },
      onRefresh() {
        this.getListData("init");
      },
      submitTask() {
        let { index, id } = this.taskItem;
        if (this.fileList[index]) {
          const examine_demo = this.fileList[index].map((item) => item.url);
          this.$Model.SubmitTask(
                  {
                    order_id: id,
                    examine_demo: examine_demo,
                  },
                  (data) => {
                    if (data.code == 1) {
                      this.fileList[index] = [];
                      /*  this.$Dialog.Toast(this.$t("task.msg[1]"), "center"); */
                    } else {
                      this.$Dialog.Toast(this.$t("task.msg[2]"), "center");
                    }
                  }
          );
        } else {
          this.$Dialog.Toast(this.$t("task.msg[2]"));
        }
      },
      cancelTask(id, index) {
        this.$Model.SubmitTask(
                {
                  order_id: id,
                  status: 6,
                },
                (data) => {
                  if (data.code == 1) {
                    this.fileList[index] = [];
                    this.getListData("init");
                  }
                }
        );
      },
      gotoTask(item) {
        this.$router.push("/taskDetail/"+item.id);
      },
    },
  };
</script>
<style scoped>
  .PageBox {
    overflow: auto;
    color: #635327;
    background-color: #0F4F4E;
  }

  .van-nav-bar {
    background-color: #0F4F4E;
  }
  .van-nav-bar>>>.van-nav-bar__title{
    color:#FFFEFF;
  }
  .van-nav-bar>>>.van-nav-bar__arrow {
    color:#FFFEFF;
  }

  .van-cell__title.record > p {
    margin-bottom: 5px;
  }

  .van-tabs__nav{
    background-color: #8c60f1;
  }
  .van-cell {
    margin-top: 5px;
    color: #8CBFC0;
  }

  .linkinfo {
    margin-left: -5px;
    text-indent: 5px;
  }

  .linkinfo a:link {
    color: #ff8a00;
  }

  .van-pull-refresh {
    min-height: calc(100vh - 90px);
  }

  .TaskItem {
    padding: 20px 10px;
    box-shadow: 0px 0px 3px #ccc;
  }
  .TaskItem .van-cell__title {
    line-height: 25px;
  }
  .item_title >>> h4 {
    font-size: 13px;
    font-family: emoji;
  }

  .orderStatus {
    text-align: center;
    height: 40px;
    color: #18D2CD;
    margin-top: -10px;
    background-color: #2D7572;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  .orderStatus2 {
    text-align: center;
    height: 40px;
    color: #B59D85;
    margin-top: -10px;
    background-color: #548780;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  .FundItem {
    width: 95%;
    background-color: #337F7B;
    margin: 8px;
    margin-bottom: 5px;
    margin-top: 8px;
    padding-bottom: 15px;
    border-radius: 8px;
  }
  .TaskItem .va .TaskItem .van-cell__value {
    overflow: visible;
  }

  .van-list {
    overflow: hidden;
  }

  .van-list >>> .mygrid {
    padding: 0;
  }

  .van-list >>> .icon a {
    width: 2.6rem;
    height: 2.6rem;
  }

  .Site >>> .van-tabs__nav {
    background: #FFFAEF;
  }


  .TaskItem .post > p,
  .TaskItem .record > p {
    color: #444;
    font-size: 12px;
    font-family: emoji;
    margin-top: 5px;
  }

  .item_title {
    margin-bottom: 15px;
    display: flex;
    flex-wrap: wrap;
  }

  .goPage {
    margin-top: 15px !important;
  }

  .goPage a {
    color: #4e51bf !important;
  }

  .ScrollBox{

  }
  .ScrollBox >>> .van-cell__value {
    margin-top: -3px;
  }

  .ScrollBox >>> .van-uploader__upload {
    background-color: #e3e6f2;
    border-radius: 4px;
  }

  .ScrollBox >>> .uploader_img .van-icon::before {
    display: none;
  }

  .van-grid-item__content {
    padding: 10px !important;
    display: flex;
    background-color:#0F4F4E;
  }

  .ScrollBox >>> .uploader_img .van-icon {
    background: url(../../public/static/icon2/task_insert_itemImg.png) no-repeat;
    width: 17px;
    height: 17px;
    background-size: 100%;
  }

  .TaskItem >>> .van-button {
    width: 73px !important;
  }
  .van-uploader >>> .van-uploader__input-wrapper button {
    width: 65px;
    padding: 5px 0;
    border-radius: 10px;
    height: auto;
    font-size: 13px;
    background: #4e51bf;
  }

  .van-uploader {
    margin-top: 30px;
  }
  .orderList {
    width: 100%;
    height: auto;
    background: transparent;
    margin-bottom: 15px;
  }
  .order {
    width: 100%;
    height: 115px;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    display: flex;
    padding: 10px;
    background: rgb(140, 96, 241);
  }
  .orderLeft {
    flex-grow: 1;
    border-right: 1.5px solid rgb(175, 155, 244);
    padding-right: 10px;
  }
  .orderLeft > img {
    display: block;
    width: 45px;
    height: 45px;
    margin: 22.5px auto;
    border-radius: 50%;
    background: #fff;
    padding: 10px;
    box-sizing: content-box;
  }
  .orderRight {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-grow: 5;
    padding-left: 10px;
  }
  .orderRight > button {
    /* width: 155px; */
    height: 55px;
    border-radius: 5px;
    border: none;
  }
</style>
